import {CreateMaterial, updateFromModel, UpdateMaterial} from "@/domain/command/material/material.command";
import {Component, Material} from "@/domain/model/material/material.model";
import store from '@/domain/store';
import {http} from "@/domain/service/api/http-api";
import {User} from "@/domain/model/user/user.model";
import {EntryDelivery} from "@/domain/model/delivery/delivery.model";
import moment from "moment";
import {uuid4} from "@capacitor/core/dist/esm/util";
import _ from "lodash";
import any = jasmine.any;
import component from "*.vue";

const simulateCreateMaterial = (command: CreateMaterial) => {
    return new Promise<Material>((resolve) => {
        setTimeout(() => {
            const material: Material = {
                id: command.id,
                ref: command.ref,
                name: command.name,
                type: command.type,
                components: command.components,
                scrapRatio: command.scrapRatio,
                group: command.group,
            };
            resolve(material);
        }, 5);
    });
};


const sanetizeCreateMaterial = (command: CreateMaterial): CreateMaterial => {
    const newComponents: Component[] = [];
    command.components.forEach((component: Component) => {
        if (component.ref === '' || !store.getters.materialByRef(component.ref))
            return;
        newComponents.push(component);
    });
    const newCommand: CreateMaterial = _.cloneDeep(command);
    newCommand.components = newComponents;
    return newCommand;
}

export function useMaterialApi() {
    const fetchById = async (id: string): Promise<void> => {
        const response = await http().get('material/' + id);
        const material: Material = response.data;
        store.commit('saveMaterial', material);
    }

    const fetchAll = async () => {
        const allMaterials = await http().get('material/');
        store.commit('saveAllMaterials', allMaterials.data);
    };

    const create = async (command: CreateMaterial): Promise<void> => {
        const sanetizedCreateMaterial: CreateMaterial = sanetizeCreateMaterial(command);
        const newCreateMaterial: CreateMaterial = _.cloneDeep(sanetizedCreateMaterial);
        const newCreateMaterialDuplicated: CreateMaterial = _.cloneDeep(command);
        newCreateMaterialDuplicated.components = newCreateMaterial.components.map((component: Component) => {
            const newComponent: any = _.cloneDeep(component);
            newComponent.id = uuid4();
            return newComponent;
        })
        await http().post('material/create', newCreateMaterialDuplicated);

        await fetchById(newCreateMaterial.id);
    };
    const createMaterials = async (commands: CreateMaterial[]): Promise<void> => {
        const allCommands: any[] = [];
        commands.forEach((command: CreateMaterial) => {
            const newCreateMaterial: CreateMaterial = _.cloneDeep(command);
            const newCreateMaterialDuplicated: CreateMaterial = _.cloneDeep(command);
            newCreateMaterialDuplicated.components = newCreateMaterial.components.map((component: Component) => {
                const newComponent: any = _.cloneDeep(component);
                newComponent.id = uuid4();
                return newComponent;
            })
            allCommands.push(newCreateMaterialDuplicated);
        });
        await http().post('material/create-materials', {createMaterials: allCommands});
        await fetchAll();
    };

    const update = async (newKit: Material) => {
        const command: UpdateMaterial = updateFromModel(newKit);
        const sanetizeCommand = _.cloneDeep(command);
        sanetizeCommand.components = command.components.map((component: Component) => {
            const newComponent: any = _.cloneDeep(component);
            newComponent.id = uuid4();
            return newComponent;
        })
        await http().post('material/update', sanetizeCommand);

        await fetchById(newKit.id);
    }

    const updateMaterialScrapRatio = async (material: Material) => {
        await update(material);

        store.commit('updateMaterialScrapRatio', material)
    }

    return {createMaterial: create, createMaterials, fetchAll, fetchById, updateMaterialScrapRatio, update};

}