import {Component, Material, TYPE} from "@/domain/model/material/material.model";
import {uuid4} from "@capacitor/core/dist/esm/util";

export interface CreateMaterial {
    id: string;
    name: string;
    ref: string;
    type: TYPE;
    components: Component[];
    scrapRatio: number;
    group: string;
}

export interface UpdateMaterial {
    id: string;
    name: string;
    components: Component[];
    scrapRatio: number;
    group: string;
}

export function newComponent(ref: string): Component {
    return {
        ref: ref,
        amount: 1,
    }

}

export function emptyComponent(): Component {
    return {
        ref: '',
        amount: 0,
    }

}


export function emptyCreateProduct(): CreateMaterial {
    return {
        id: uuid4(),
        name: '',
        ref: '',
        type: 'production',
        components: [
            emptyComponent()
        ],
        scrapRatio: 0.01,
        group: '',
    }
}

export function updateFromModel(material: Material): UpdateMaterial {
    return {
        id: material.id,
        name: material.name,
        components: material.components,
        scrapRatio: material.scrapRatio,
        group: material.group,
    };
}
