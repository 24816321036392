import {Reference} from "@/domain/model/reference/ref.model";
import _, {isEqual} from "lodash";
import {Material} from "@/domain/model/material/material.model";

export interface ReferenceState {
    references: Reference[];
    materials: Material[];
}

const initialState: ReferenceState = {
    references: [],
    materials: []
}

const saveMaterial = (state: ReferenceState, newMaterial: Material): any => {
    if (_.some(state.materials, (materialNode) => materialNode.ref === newMaterial.ref)) {
        state.materials = state.materials.map((materialNode) => {
            if (materialNode.id === newMaterial.id || materialNode.ref === newMaterial.ref) {
                return newMaterial;
            } else {
                return materialNode;
            }
        });
    } else {
        state.materials.push(newMaterial)
    }
}

export const referenceModule = {
    state: () => (_.cloneDeep(initialState)),
    actions: {},
    getters: {
        allReferences: (state: ReferenceState): Reference[] => state.references,
        allMaterial: (state: ReferenceState): Material[] => [...state.materials],
        allProductionMaterials: (state: ReferenceState): Material[] => _.filter(state.materials, (material: Material) => material.type === 'production'),
        allPartsMaterials: (state: ReferenceState): Material[] => _.filter(state.materials, (material: Material) => material.type === 'part'),
        materialById: (state: ReferenceState) => (id: string) => _.find(state.materials, ['id', id]),
        materialByRef: (state: ReferenceState) => (ref: string): Material | undefined => {
            return _.find(state.materials, ['ref', ref])
        },
        entryDeliveriesByParams: (state: ReferenceState) => (nextPageToLoad: number, maxNumberAdded: number) => {
            console.log(nextPageToLoad, maxNumberAdded, [...state.materials.splice(nextPageToLoad, nextPageToLoad + maxNumberAdded)]);
            return [...state.materials.splice(nextPageToLoad, nextPageToLoad + maxNumberAdded)];
        },

    },
    mutations: {
        resetMaterials: (state: any) => {
            const resetState: any = {...initialState};
            for (const key in resetState) {
                state[key] = _.cloneDeep(resetState[key]);

            }
        },
        saveMaterial: (state: ReferenceState, newMaterial: Material): any => saveMaterial(state, newMaterial),
        saveAllMaterials: (state: ReferenceState, allMaterials: Material[]) => {
            if (isEqual(state.materials, allMaterials))
                return;

            allMaterials.forEach((material: Material) => {
                saveMaterial(state, material)
            });
        },


        addComponent: (state: ReferenceState, argument: any) => {
            const materialUpdated: number = _.findIndex(state.materials, ['ref', argument.kitRef]);
            if (materialUpdated != -1) {
                state.materials[materialUpdated].components.push(argument.newComponent);
            }
        },

        updateMaterialScrapRatio: (state: ReferenceState, newKit: Material) => {
            state.materials = state.materials.map((materialNode: Material) => {
                if (materialNode.id === newKit.id)
                    return newKit;
                else
                    return materialNode;
            })
        },
    },
    plugins: []
};
