import store from "@/domain/store";
import {
    CreateEntryDelivery,
    CreateExitDelivery,
    UpdateEntryDelivery,
    UpdateExitDelivery
} from "@/domain/command/delivery/delivery.command";
import {CreateMaterial} from "@/domain/command/material/material.command";

export function useViewStateApi() {
    const entryDeliveryBeforeCheck = (command: CreateEntryDelivery): void => {
        store.commit('saveCheckCreateEntryDeliveryState', command);
    };

    const updateEntryDeliveryBeforeCheck = (command: UpdateEntryDelivery): void => {
        store.commit('saveCheckUpdateEntryDeliveryState', command);
    };

    const updateExitDeliveryBeforeCheck = (command: UpdateExitDelivery): void => {
        store.commit('saveCheckUpdateExitDeliveryState', command);
    };

    const resetCheckUpdateEntryDeliveryState = (): void => {
        store.commit('resetCheckUpdateEntryDeliveryState');
    };

    const resetCheckUpdateExitDeliveryState = (): void => {
        store.commit('resetCheckUpdateExitDeliveryState');
    };

    const kitBeforeSave = (command: CreateMaterial): void => {
        store.commit('saveKitState', command);
    };
    const resetCheckCreateEntryDeliveryState = (): void => {
        store.commit('resetCheckCreateEntryDeliveryState');
    };

    const exitDeliveryBeforeCheck = (command: CreateExitDelivery): void => {
        store.commit('saveCheckCreateExitDeliveryState', command);
    };

    const resetCheckCreateExitDeliveryState = (): void => {
        store.commit('resetCheckCreateExitDeliveryState');
    };
    const resetKitState = (): void => {
        store.commit('resetKitState');
    };

    const kitBeforeChange = (): void => {
        store.commit('saveKitState');
    };

    const showMenuToTrue = (): void => {
        store.commit('showMenuToTrue');
    };

    const showMenuToFalse = (): void => {
        store.commit('showMenuToFalse');
    };


    return {
        showMenuToTrue,
        showMenuToFalse,
        updateExitDeliveryBeforeCheck,
        resetCheckUpdateExitDeliveryState,
        resetCheckUpdateEntryDeliveryState,
        updateEntryDeliveryBeforeCheck,
        kitBeforeSave,
        resetKitState,
        kitBeforeChange,
        entryDeliveryBeforeCheck,
        resetCheckCreateEntryDeliveryState,
        exitDeliveryBeforeCheck,
        resetCheckCreateExitDeliveryState
    };

}