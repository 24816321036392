

import {computed, defineComponent} from "vue";
import {
  triangleOutline,
  listOutline, calendarOutline, todayOutline, peopleOutline,
} from "ionicons/icons";
import {
  IonButtons,
  IonContent,
  IonHeader, IonIcon, IonLabel,
  IonMenuButton,
  IonPage, IonTabBar, IonTabButton,
  IonTabs,
  IonTitle, IonToolbar
} from "@ionic/vue";
import {useDevice} from "@/app/service/device/device";

export default defineComponent({
  name: "User",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonTabs,
    IonTitle,
    IonToolbar,
    IonMenuButton,
    IonButtons,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,

  },
  setup() {
    const {isMobile} = useDevice();
    const tabsPosition = computed(() => isMobile.value ? 'bottom' : 'top');
    return {
      tabsPosition,
      calendarOutline,
      todayOutline,
      peopleOutline,
    };
  }
})
