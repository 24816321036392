import store from '@/domain/store';
import {
    CreatePendingOrder,
    CreateOrderPlan,
    CreateProducedOrder,
    CreateActiveOrder, UpdateOrder, updateFromModel
} from "@/domain/command/order/order.command";
import {Order, OrderPlan, ProducedOrder} from "@/domain/model/order/order.model";
import moment from 'moment';
import _ from "lodash";
import {http} from "@/domain/service/api/http-api";
import {Material} from "@/domain/model/material/material.model";
import {emptyMaterial} from "@/domain/service/model/material.service";


const simulateCreateProducedOrder = (command: CreateProducedOrder) => {
    return new Promise<ProducedOrder>((resolve) => {
        setTimeout(() => {
            const producedOrder: ProducedOrder = {
                id: command.id,
                orderId: command.orderId,
                amount: command.amount,
                date: moment().unix(),
                comments: command.comments,
                scrapAmount: command.scrap,
                state: 'active'
            }
            resolve(producedOrder);
        }, 0);
    });
};


const simulateCreateOrderPlan = async (command: CreateOrderPlan) => {
    return new Promise<OrderPlan>((resolve) => {
        setTimeout(() => {
            const orderPlan: OrderPlan = {
                id: command.id,
                orders: command.orders,
            };
            resolve(orderPlan);
        }, 0);
    });
};

export function useOrderPlanApi() {
    const createOrderPlan = async (command: CreateOrderPlan): Promise<void> => {
        const newOrderPlan = await simulateCreateOrderPlan(command);
        store.commit('saveOrderPlan', newOrderPlan);
    };

    return {createOrderPlan};
}

const sanelizeOrder = (allMaterials: Material[], order: any): Order => {
    const newOrder: any = _.cloneDeep(order);

    const newDeliveryDate = new Date(newOrder.deliveryDate).getTime();
    newOrder.deliveryDate = Number((moment(newDeliveryDate).format('X')));

    const newReceivedDate = new Date(newOrder.receivedDate).getTime();
    newOrder.receivedDate = Number((moment(newReceivedDate).format('X')));

    newOrder.producedOrder = [];
    newOrder.material = allMaterials.find((material: Material) =>
        material.id === order.materialId);

    newOrder.producedOrder = _.filter(newOrder.producedOrder, (producedOrder: ProducedOrder) => producedOrder.state === 'active')

    return newOrder;
};


export const sanetizeProducedOrders = (producedOrder: any): ProducedOrder => {
    const newProducedOrder = _.cloneDeep(producedOrder);
    const newHour = new Date(producedOrder.date).getTime();
    newProducedOrder.date = Number((moment(newHour).format('X')));
    return newProducedOrder;
};

export function useOrderApi() {
    const fetchById = async (id: string) => {
        const response = await http().get('order/' + id);
        const allMaterials = store.getters.allMaterial;

        const order: Order = sanelizeOrder(allMaterials, response.data);

        store.commit('saveOrder', response.data);
    }

    const fetchAll = async () => {
        const response = await http().get('order/');
        const allMaterials = store.getters.allMaterial;
        const allOrders = response.data.map((order: any) => sanelizeOrder(allMaterials, order));

        store.commit('saveAllOrders', allOrders);
    };

    const createPending = async (command: CreatePendingOrder) => {
        const order: Order = {
            id: command.id,
            material: command.material,
            expectedAmount: command.expectedAmount,
            scrapAmount: Number(command.expectedAmount) / 100,
            producedScrapAmount: 0,
            producedAmountWithScrap: 0,
            pendingAmount: 0,
            producedOrder: [],
            receivedDate: moment().unix(),
            deliveryDate: command.endDate,
            state: 'pending',
        };

        store.commit('savePendingOrder', order);
    };

    const createAllPendingOrders = async (commands: CreatePendingOrder[]) => {
        const newOrders = commands.map((command: CreatePendingOrder) => {
            return {
                id: command.id,
                material: command.material,
                expectedAmount: command.expectedAmount,
                scrapAmount: Number(command.expectedAmount) / 100,
                producedScrapAmount: 0,
                producedAmountWithScrap: 0,
                pendingAmount: 0,
                producedOrder: [],
                receivedDate: moment().unix(),
                deliveryDate: command.endDate,
                state: 'pending',
            };
        })
        store.commit('saveAllPendingOrder', newOrders);
    }

    const activateAllOrdersCommit = async () => {
        const pendingOrders: Order[] = store.getters.allPendingOrders;
        const requests = [];

        for (const pendingOrder of pendingOrders) {
            const command: CreateActiveOrder = {
                id: pendingOrder.id,
                materialId: pendingOrder.material.id,
                expectedAmount: pendingOrder.expectedAmount,
                receivedDate: new Date(pendingOrder.receivedDate * 1000),
                endDate: new Date(pendingOrder.deliveryDate * 1000)
            };
            requests.push(http().post('order/create', command));
            // await http().post('order/create', command)
        }
        const allRequests = Promise.all(requests);
        await allRequests;
        await fetchAll();
    }

    const finalizeOrderCommit = async (order: Order) => {
        const command: UpdateOrder = updateFromModel(order);
        command.state = 'finalized';

        await http().post('order/update', command);
        await fetchById(order.id);
    }

    const finalizeAllOrdersCommit = async () => {
        const activeOrders: Order[] = store.getters.allActiveOrders;
        const requests = [];
        for (const activeOrder of activeOrders) {
            const command: UpdateOrder = updateFromModel(activeOrder);
            command.state = 'finalized';
            requests.push(http().post('order/update', command));
            // await finalizeOrderCommit(activeOrder);
            // store.commit('finalizeAllOrders');
        }
        const allRequests = Promise.all(requests);
        await allRequests;
        await fetchAll();
    }

    const eliminateOrderCommit = (orderId: string) => {
        store.commit('eliminateOrder', orderId);
    }

    const activateOrderCommit = (order: Order) => {
        store.commit('activateOrder', order);
    }

    return {
        fetchById,
        fetchAll,
        createOrder: createPending,
        finalizeAllOrdersCommit,
        finalizeOrderCommit,
        activateAllOrdersCommit,
        eliminateOrderCommit,
        activateOrderCommit,
        createAllPendingOrders,
    };

}


export function useProducedOrderApi() {
    const createProducedOrder = async (command: CreateProducedOrder): Promise<void> => {
        const newProducedOrder = await simulateCreateProducedOrder(command);
        store.commit('saveProducedOrder', newProducedOrder);
    };

    return {createProducedOrder};

}