import {ProductionDailyUserBonus, ProductionUserBonus} from "@/domain/model/productionBonus/bonus.model";
import _, {cloneDeep} from "lodash";
import {formatDate} from "@/domain/helper/date.helper";


export interface BonusesState {
    bonuses: ProductionUserBonus[];
    dailyBonuses: ProductionDailyUserBonus[];
}

const initialState: BonusesState = {
    bonuses: [],
    dailyBonuses: [],
}

export const bonusModule = {
    state: () => (_.cloneDeep(initialState)),
    mutations: {
        saveBonus: (state: BonusesState, newBonus: ProductionUserBonus) => {
            if (!_.find(state.bonuses, (bonusNode: ProductionUserBonus) => newBonus.id === bonusNode.id)) {
                state.bonuses = [...state.bonuses, cloneDeep(newBonus)];
            } else
                state.bonuses = state.bonuses.map((bonusNode: ProductionUserBonus) => {
                    if (bonusNode.id === newBonus.id)
                        return _.cloneDeep(newBonus);
                    else
                        return _.cloneDeep(bonusNode);
                });
        },

        saveDailyBonus: (state: BonusesState, newBonus: ProductionDailyUserBonus) => {
            if (!_.find(state.dailyBonuses, (bonusNode: ProductionDailyUserBonus) => newBonus.id === bonusNode.id)) {
                state.dailyBonuses = [...cloneDeep(state.dailyBonuses), {...newBonus}];
            } else
                state.dailyBonuses = state.dailyBonuses.map((bonusNode: ProductionDailyUserBonus) => {
                    if (bonusNode.id === newBonus.id)
                        return newBonus;
                    else
                        return bonusNode;
                });
        },
        saveAllDailyBonus: (state: BonusesState, newBonuses: ProductionDailyUserBonus[]) => {
            state.dailyBonuses = [...cloneDeep(newBonuses)];
        },
    },
    actions: {},
    getters: {
        allBonus: (state: BonusesState) => (): ProductionUserBonus[] => {
            return state.bonuses;
        },
        bonusById: (state: BonusesState) => (id: string): ProductionUserBonus | undefined => {
            return _.find(state.bonuses, (bonusNode: ProductionUserBonus) => {
                return bonusNode.id === id
            })
        },
        bonusByUserId: (state: BonusesState) => (id: string): ProductionUserBonus | undefined => {
            return _.find(state.bonuses, (bonusNode: ProductionUserBonus) => {
                return bonusNode.user.id === id
            })
        },
        bonusByUserIdAndDateRange: (state: BonusesState) => (id: string, date1: number, date2: number): ProductionUserBonus | undefined => {
            return  _.find(state.bonuses, (bonusNode: ProductionUserBonus) => {
                return bonusNode.user.id === id && bonusNode.date * 1000 >= date1 && bonusNode.date * 1000 <= date2
            })
        },
        allDailyBonuses: (state: BonusesState) => (): ProductionDailyUserBonus[] => {
            return state.dailyBonuses;
        },
        dailyUserBonusByUserId: (state: BonusesState) => (id: string): ProductionDailyUserBonus | undefined => {
            return _.find(state.dailyBonuses, (bonusNode: ProductionDailyUserBonus) => {
                return bonusNode.user.id === id
            })
        },
        dailyUserBonusById: (state: BonusesState) => (id: string): ProductionDailyUserBonus | undefined => {
            return _.find(state.dailyBonuses, (bonusNode: ProductionDailyUserBonus) => {
                return bonusNode.id === id
            })
        },
        dailyBonusByUserId: (state: BonusesState) => (id: string): ProductionUserBonus | undefined => {
            return _.find(state.bonuses, (bonusNode: ProductionUserBonus) => {
                const today = new Date().setUTCHours(0, 0, 0, 0);
                const thatDay = new Date(bonusNode.date * 1000).setUTCHours(0, 0, 0, 0);
                if (bonusNode.user.id === id)
                    return thatDay === today;
                else
                    return false;
            })
        },
        allUserBonusByMonth: (state: BonusesState) => (userId: string, month: any): ProductionUserBonus[] => {
            const allBonusByUserId: ProductionUserBonus[] = _.filter(state.bonuses, (bonusNode: ProductionUserBonus) => bonusNode.user.id === userId);
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), month, 1);
            const lastDay = new Date(date.getFullYear(), month + 1, 0);
            return _.filter(allBonusByUserId, (bonus: ProductionUserBonus) => new Date(bonus.date * 1000) >= firstDay && new Date(bonus.date * 1000) <= lastDay)
        },

        allUserBonusByDates: (state: BonusesState) => (userId: string, initDate: string, endDate: string): ProductionUserBonus[] => {
            const allBonusByUserId: ProductionUserBonus[] =
                _.filter(state.bonuses, (bonusNode: ProductionUserBonus) => !bonusNode.user ? false : bonusNode.user.id === userId);
            return _.filter(allBonusByUserId, (bonus: ProductionUserBonus) => {
                const dateEndDate = new Date(endDate);
                const newEndDate = dateEndDate.setUTCDate(dateEndDate.getUTCDate() + 1);
                return bonus.date >= Number(formatDate(initDate, 'X')) && bonus.date <= newEndDate / 1000;
            })
        },

        allUserBonusMaterialByDates: (state: BonusesState) => (userId: string, initDate: number, endDate: number): ProductionUserBonus[] => {
            const allBonusByUserId: ProductionUserBonus[] =
                _.filter(state.bonuses, (bonusNode: ProductionUserBonus) => !bonusNode.user ? false : bonusNode.user.id === userId);
            return _.filter(allBonusByUserId, (bonus: ProductionUserBonus) => {
                return bonus.date >=  initDate/1000 && bonus.date <= endDate/1000;
            })
        },
        dailyUserBonusByUserIdAndDates: (state: BonusesState) => (id: string, initDate: number, endDate: number): ProductionDailyUserBonus[] | undefined => {
            const userDaily = _.filter(state.dailyBonuses, (bonusNode: ProductionDailyUserBonus) => {
                return bonusNode.user.id === id;
            })
            return userDaily.filter((daily: ProductionDailyUserBonus) => {
                return daily.date >= initDate/1000 && daily.date <= endDate / 1000;
            })
        },
    },
    plugins: []
};