import {uuid4} from "@capacitor/core/dist/esm/util";
import {
    CreateEntryDelivery,
    CreateEntryDeliveryItem,
    CreateExitDelivery, emptyExitDeliveryCommand, emptyExitDeliveryItemCommand, UpdateEntryDelivery, UpdateExitDelivery
} from "@/domain/command/delivery/delivery.command";
import {CreateMaterial, emptyCreateProduct} from "@/domain/command/material/material.command";
import _ from "lodash";
import {CreateUser, UpdateUser} from "@/domain/command/user/user.command";
import moment from "moment";

export interface ViewState {
    entryDeliveryBeforeCheck: CreateEntryDelivery;
    updateEntryDeliveryBeforeCheck: UpdateEntryDelivery;
    updateExitDeliveryBeforeCheck: UpdateExitDelivery;
    exitDeliveryBeforeCheck: CreateExitDelivery;
    kitBeforeCreating: CreateMaterial;
    createMaterialInList: CreateMaterial;
    createProductInList: CreateMaterial;
    newEntryDeliveryItem: CreateEntryDeliveryItem;
    createUserInList: CreateUser;
    updateUserInList: UpdateUser;
    showMenu: boolean;
}

export const initialState: ViewState = {
    showMenu: true,
    kitBeforeCreating: emptyCreateProduct(),
    updateExitDeliveryBeforeCheck: {
        id: uuid4(),
        indexPosition: '0',
        date: moment().unix(),
        items: [
            emptyExitDeliveryItemCommand()
        ],
    },
    updateEntryDeliveryBeforeCheck: {
        id: uuid4(),
        indexPosition: '0',//
        date: Math.floor(Date.now() / 1000) * 1000,
        items: [
            {receivedAmount: 0, expectedAmount: 0, reference: '', id: uuid4()}
        ],
    },
    entryDeliveryBeforeCheck: {
        id: uuid4(),
        indexPosition: '0',//
        date: Math.floor(Date.now() / 1000) * 1000,
        items: [
            {receivedAmount: 0, expectedAmount: 0, reference: '', id: uuid4()}
        ],
    },
    exitDeliveryBeforeCheck: {
        id: uuid4(),
        // date: Math.floor(Date.now() / 1000) * 1000,
        indexPosition: '0',
        date: moment().unix(),
        items: [
            emptyExitDeliveryItemCommand()
        ],
    },
    createMaterialInList: {
        id: uuid4(),
        name: '',
        ref: '',
        type: 'part',
        components: [],
        scrapRatio: 0,
        group: '',
    },
    createProductInList: {
        id: uuid4(),
        name: '',
        ref: '',
        type: 'production',
        components: [],
        scrapRatio: 0.01,
        group: '',
    },
    newEntryDeliveryItem: {
        receivedAmount: 0,
        expectedAmount: 0,
        reference: '',
        id: uuid4(),
    },
    createUserInList: {
        id: uuid4(),
        username: '',
        password: '',
        email: '',
        charge: '',
        shift: 'central',
        workerNumber: 0,
    },
    updateUserInList: {
        id: '',
        username: '',
        email: '',
        charge: '',
        shift: 'central',
        dailyBonusIncome: 0,
    }
};

export const emptyState = _.cloneDeep(initialState);


export const viewStateModule = {
    state: () => (_.cloneDeep(initialState)),
    mutations: {
        resetViewState: (state: any) => {
            const resetState: any = {...initialState};
            for (const key in resetState) {
                state[key] = _.cloneDeep(resetState[key]);

            }
        },
        saveKitState: (state: ViewState, kit: CreateMaterial) => {
            state.kitBeforeCreating = kit;
        },

        saveCheckCreateEntryDeliveryState: (state: ViewState, entryDelivery: CreateEntryDelivery) => {
            state.entryDeliveryBeforeCheck = entryDelivery;

        },

        saveCheckUpdateEntryDeliveryState: (state: ViewState, entryDelivery: UpdateEntryDelivery) => {
            state.updateEntryDeliveryBeforeCheck = entryDelivery;
        },
        saveCheckUpdateExitDeliveryState: (state: ViewState, exitDelivery: UpdateExitDelivery) => {
            state.updateExitDeliveryBeforeCheck = exitDelivery;
        },

        resetCheckCreateEntryDeliveryState: (state: ViewState) => {
            state.entryDeliveryBeforeCheck = {
                id: uuid4(),
                indexPosition: '0',
                date: Math.floor(Date.now() / 1000) * 1000,
                items: [
                    {receivedAmount: 0, expectedAmount: 0, reference: '', id: uuid4()}
                ],
            };

        },

        saveCheckCreateExitDeliveryState: (state: ViewState, exitDelivery: CreateExitDelivery) => {
            state.exitDeliveryBeforeCheck = exitDelivery;

        },
        resetCheckCreateExitDeliveryState: (state: ViewState) => {
            state.exitDeliveryBeforeCheck = emptyExitDeliveryCommand();
        },
        resetCheckUpdateEntryDeliveryState: (state: ViewState) => {
            state.updateEntryDeliveryBeforeCheck = {
                id: uuid4(),
                indexPosition: '0',//
                date: Math.floor(Date.now() / 1000) * 1000,
                items: [
                    {receivedAmount: 0, expectedAmount: 0, reference: '', id: uuid4()}
                ],
            };
        },

        resetCheckUpdateExitDeliveryState: (state: ViewState) => {
            state.updateExitDeliveryBeforeCheck = {
                id: uuid4(),
                // date: Math.floor(Date.now() / 1000) * 1000,
                indexPosition: '0',
                date: moment().unix(),
                items: [
                    emptyExitDeliveryItemCommand()
                ],
            };
        },
        resetKitState: (state: ViewState) => {
            state.kitBeforeCreating = emptyCreateProduct();
        },

        showMenuToTrue: (state: ViewState) => {
            state.showMenu = true;
        },
        showMenuToFalse: (state: ViewState) => {
            state.showMenu = false;
        },

    },
    actions: {},
    getters: {
        getShowMenu:(state: ViewState): boolean => {
           return state.showMenu;
        } ,
        getCheckCreateEntryDeliveryState: (state: ViewState): CreateEntryDelivery => {
            return state.entryDeliveryBeforeCheck
        },
        getCheckUpdateEntryDeliveryState: (state: ViewState): UpdateEntryDelivery => {
            return state.updateEntryDeliveryBeforeCheck
        },
        getCheckCreateExitDeliveryState: (state: ViewState): CreateExitDelivery => {
            return state.exitDeliveryBeforeCheck
        },
        getKitBeforeCreating: (state: ViewState): CreateMaterial => {
            return state.kitBeforeCreating
        },
        getCheckUpdateExitDeliveryState: (state: ViewState): UpdateExitDelivery => {
            return state.updateExitDeliveryBeforeCheck
        },
    },
    plugins: []
};
