import {createApp} from 'vue'
import App from './app/App.vue'
import router from './app/router';

import {IonicVue, isPlatform, getPlatforms} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/utilities.css';
import './theme/global.css';

import './registerServiceWorker'

import store from './domain/store'


const config = {
    animated: !isPlatform('desktop'),
    rippleEffect: false,
    swipeBackEnabled: false,
    mode: 'ios',
    backButtonText: 'Volver'
};

const app = createApp(App)
    .use(store)
    .use(IonicVue, config)
    .use(router);

router.isReady().then(() => {
    app.mount('#app');
});