import {Component, Material} from "@/domain/model/material/material.model";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {CreateMaterial} from "@/domain/command/material/material.command";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {CreatePendingOrder} from "@/domain/command/order/order.command";
import moment from 'moment';
import {useOrderApi} from "@/domain/service/api/order.api";
import {CreateEntryDelivery, CreateEntryDeliveryItem} from "@/domain/command/delivery/delivery.command";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {useStore} from "vuex";


export function useTriggerDemo() {
    const today = moment();
    const todayPlus7 = today.add(7, 'days');
    const store = useStore();

    const createDemo = async () => {
        console.log('deleting old state...');
        console.log('deleting deliveries...');
        store.commit('resetDeliveries');
        console.log('deleting orders...');
        store.commit('resetOrders');
        console.log('deleting material...');
        store.commit('resetMaterials');
        console.log('deleting stock...');
        store.commit('resetStock');
        console.log('deleting users...');
        store.commit('resetUsers');
        console.log('deleting views...');
        store.commit('resetViewState');

        const {createMaterial} = useMaterialApi();
        const {createOrder, activateAllOrdersCommit} = useOrderApi();
        const {createEntryDelivery} = useDeliveryApi();
        console.log('loading demo...');
        const material1: Material = {
            id: uuid4(),
            ref: 'MATERIALREF1',
            name: 'Descripcion Material 1',
            type: 'part',
            components: [],
            scrapRatio: 0,
            group: 'KIT',
        }
        const material2: Material = {
            id: uuid4(),
            ref: 'MATERIALREF2',
            name: 'Descripcion Material 2',
            type: 'part',
            components: [],
            scrapRatio: 0,
            group: 'KIT',
        }
        const material3: Material = {
            id: uuid4(),
            ref: 'MATERIALREF3',
            name: 'Descripcion Material 3',
            type: 'part',
            components: [],
            scrapRatio: 0,
            group: 'KIT',
        }
        const material4: Material = {
            id: uuid4(),
            ref: 'MATERIALREF4',
            name: 'Descripcion Material 4',
            type: 'part',
            components: [],
            scrapRatio: 0,
            group: 'KIT',
        }
        // const material5: Material = {
        //     id: uuid4(),
        //     ref: 'MATERIALREF5',
        //     name: 'MATERIAL5',
        //     type: 'part',
        //     components: [],
        //     scrapRatio: 0,
        // }


        const component1: Component = {
            ref: 'MATERIALREF1',
            amount: 1
        }
        const component2: Component = {
            ref: 'MATERIALREF2',
            amount: 2
        }
        const component22: Component = {
            ref: 'MATERIALREF2',
            amount: 1
        }
        const component3: Component = {
            ref: 'MATERIALREF3',
            amount: 1
        }
        const component4: Component = {
            ref: 'MATERIALREF4',
            amount: 2
        }
        // const component5: Component = {
        //     ref: 'MATERIALREF5',
        //     amount: 3
        // }

        const kit1: Material = {
            id: uuid4(),
            ref: 'KITREF1',
            name: 'KIT1',
            type: 'production',
            components: [component1, component2],
            scrapRatio: 0.01,
            group: 'KIT',
        }
        const kit2: Material = {
            id: uuid4(),
            ref: 'KITREF2',
            name: 'KIT2',
            type: 'production',
            components: [component3, component4],
            scrapRatio: 0.01,
            group: 'KIT',
        }
        const kit3: Material = {
            id: uuid4(),
            ref: 'KITREF3',
            name: 'KIT3',
            type: 'production',
            components: [component1, component22, component4],
            scrapRatio: 0.01,
            group: 'KIT',
        }
        const materials = [material1, material2, material3, material4];//, material5];
        const kits = [kit1, kit2, kit3];
        const CreateEntryDeliveryItems = [];
        const entryDeliveries = [1000, 2000, 1000, 3000];
        const orders = [600, 800, 300];
        console.log('loading materials and orders...');
        for (let i = 0; i < 4; i++) {
            const createMaterialCommand: CreateMaterial = {
                id: materials[i].id,
                name: materials[i].name,
                ref: materials[i].ref,
                type: materials[i].type,
                components: materials[i].components,
                scrapRatio: materials[i].scrapRatio,
                group: 'KIT',
            }
            await createMaterial(createMaterialCommand);

            const entryDeliveryItemsCommand: CreateEntryDeliveryItem = {
                receivedAmount: entryDeliveries[i],
                expectedAmount: Math.round((Math.random() * (11 - 1) + 1)) * 1000,
                reference: materials[i].ref,
                id: uuid4(),
            }
            CreateEntryDeliveryItems.push(entryDeliveryItemsCommand);

        }
        for (let i = 0; i < 3; i++) {
            const createKitCommand: CreateMaterial = {
                id: kits[i].id,
                name: kits[i].name,
                ref: kits[i].ref,
                type: kits[i].type,
                components: kits[i].components,
                scrapRatio: 0.01,
                group: 'KIT',
            }
            await createMaterial(createKitCommand);

            const orderCommand: CreatePendingOrder = {
                id: uuid4(),
                material: kits[i],
                expectedAmount: orders[i],
                receivedDate: moment().unix(),
                endDate: todayPlus7.unix(),
            }
            await createOrder(orderCommand)
        }
        console.log('activating orders...');
        await activateAllOrdersCommit();


        const entryDeliveryCommand: CreateEntryDelivery = {
            id: uuid4(),
            indexPosition: '0',
            date: moment().unix(),
            items: CreateEntryDeliveryItems,
        }

        console.log('loading entry deliveries...');
        await createEntryDelivery(entryDeliveryCommand);


    }
    return {createDemo}
}