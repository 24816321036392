import _, {isEqual, remove} from "lodash";
import {Order, OrderPlan, ProducedOrder} from "@/domain/model/order/order.model";
import {Component, Material} from "@/domain/model/material/material.model";

export interface OrderState {
    orderPlans: OrderPlan[];
    orders: Order[];
    producedOrders: ProducedOrder[];
}

const initialState: OrderState = {
    orderPlans: [],
    orders: [],
    producedOrders: [],
}

export const orderModule = {
    state: () => (_.cloneDeep(initialState)),
    mutations: {
        resetOrders: (state: any) => {
            const resetState: any = {...initialState};
            for (const key in resetState) {
                state[key] = _.cloneDeep(resetState[key]);
            }
        },

        savePendingOrder: (state: OrderState, newOrder: Order) => {
            if (_.some(state.orders, (orderNode: Order) => orderNode.id === newOrder.id)) {
                state.orders = state.orders.map((orderNode: Order) => {
                    if (orderNode.id === newOrder.id) {
                        return newOrder;
                    } else {
                        return orderNode;
                    }
                });
            } else
                state.orders.push(newOrder);
        },

        saveAllPendingOrder: (state: OrderState, orders: Order[]) => {
            orders.forEach((order: Order) =>
                !_.some(state.orders, (orderNode: Order) => orderNode.id === order.id) ? state.orders.push(order) : console.log());
        },

        saveAllOrders: (state: OrderState, newOrders: Order[]) => {
            const ordersWithoutPending: Order[] = [...state.orders];
            remove(ordersWithoutPending, (order: Order) => order.state === 'pending');
            if (isEqual(ordersWithoutPending, newOrders))
                return;
            const pendings =  _.filter([...state.orders], (order: Order) => order.state === 'pending');
            state.orders = _.unionBy(newOrders, pendings, 'id');
        },

        saveProducedOrder: (state: OrderState, newProducedOrder: ProducedOrder) => {
            const order = _.find(state.orders, ['id', newProducedOrder.orderId]);
            if (!order)
                return;
            state.producedOrders.push(newProducedOrder);
            const newOrder = {...order};
            newOrder.producedOrder.push(newProducedOrder);
            state.orders.map((orderNode: Order) => {
                if (orderNode.id === newOrder.id)
                    return newOrder;
                else
                    return orderNode;
            });
        },
        saveAllProducedOrder: (state: OrderState, newProducedOrder: ProducedOrder[]) => state.producedOrders = newProducedOrder,
        finalizeAllOrders: (state: OrderState) => {
            const newOrders: Order[] = [];
            state.orders.forEach((order: Order) => {
                if (order.state === 'active') {
                    const newOrder: Order = {...order};
                    newOrder.state = 'finalized';
                    newOrders.push(newOrder);
                } else {
                    const newOrder: Order = {...order};
                    newOrders.push(newOrder);
                }
            })
            state.orders = newOrders;
        },
        saveOrder: (state: OrderState, order: Order) => {
            if (!_.some(state.orders, (orderNode: Order) => orderNode.id === order.id))
                state.orders.push(order);
            else
                state.orders = state.orders.map((orderNode: Order) => (orderNode.id === order.id) ? order : orderNode);
        },
        activateAllOrders: (state: OrderState) => {
            const newOrders: Order[] = [];
            state.orders.forEach((order: Order) => {
                if (order.state === 'pending') {
                    const newOrder: Order = {...order};
                    newOrder.state = 'active';
                    newOrders.push(newOrder);
                } else {
                    const newOrder: Order = {...order};
                    newOrders.push(newOrder);
                }

            })
            state.orders = newOrders;
        },

        eliminateOrder: (state: OrderState, orderId: string) => {
            const newOrdersState: Order[] = [];
            state.orders.forEach((orderNode: Order) => {
                if (orderNode.id !== orderId)
                    newOrdersState.push(orderNode);
                else
                    return;
            })
            state.orders = newOrdersState;
        },
        activateOrder: (state: OrderState, order: Order) => {
            const newOrdersState: Order[] = [];
            const newOrder: Order = {...order};
            newOrder.state = 'active';
            state.orders.forEach((orderNode: Order) => {
                if (orderNode.id === order.id) {
                    newOrdersState.push(newOrder);
                } else
                    newOrdersState.push(orderNode);
            })
            state.orders = newOrdersState;
        }

    },
    actions: {},
    getters: {
        allOrderPlans: (state: OrderState): OrderPlan[] => [...state.orderPlans],
        allOrders: (state: OrderState): Order[] => [...state.orders],

        allFinalizedOrders: (state: OrderState): Order[] => _.filter([...state.orders], (order: Order) => order.state === 'finalized'),
        allActiveOrders: (state: OrderState): Order[] => _.filter([...state.orders], (order: Order) => order.state === 'active'),
        allPendingOrders: (state: OrderState): Order[] => _.filter([...state.orders], (order: Order) => order.state === 'pending'),

        allPendingAndActiveOrders: (state: OrderState): Order[] => _.filter([...state.orders], (order: Order) => order.state === 'active' || order.state === 'pending'),

        orderPlanById: (state: OrderState) => (id: string) => _.find(state.orderPlans, ['id', id]),
        orderById: (state: OrderState) => (id: string) => _.find(state.orders, ['id', id]),
        ordersByKit: (state: OrderState) => (material: Material) =>
            _.filter(state.orders, (order: Order) => {
                if (order.material.id === material.id)
                    return true;
                return _.find(order.material.components, (component: Component) => component.ref === material.ref);
            }),
        activeOrdersByKit: (state: OrderState) => (material: Material) =>
            _.filter(state.orders, (order: Order) => {
                if (order.state === "active") {
                    if (order.material.id === material.id)
                        return true;
                    return _.find(order.material.components, (component: Component) => component.ref === material.ref);
                } else
                    return false;
            }),

        ordersByMaterial: (state: OrderState) => (material: Material) =>
            _.filter(state.orders, (order: Order) => {
                return _.some(order.material.components, (component: Component) => {
                    return component.ref === material.ref;
                });
            }),
        activeOrdersByMaterial: (state: OrderState) => (material: Material) =>
            _.filter(state.orders, (order: Order) => {
                if (order.state === 'active')
                    return _.some(order.material.components, (component: Component) => {
                        return component.ref === material.ref;
                    });
                else
                    return false;
            }),

        producedOrderById: (state: OrderState) => (id: string) => _.find(state.producedOrders, ['id', id]),
        orderByKitRef: (state: OrderState) => (ref: string) => {
            const filtered = _.filter(state.orders, (order: Order) => order.material.ref === ref);
            const founded = _.find(filtered);
            return founded;
        },
    },
    plugins: []
};