import {Drivers, Storage} from '@ionic/storage';

const store = new Storage({
        name: '__catedier',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }
);

export const useLocalstorage = () => {
    const create = async () => store.create();

    const set = async (key: string, value: any) => store.set(key, value);

    const get = async (key: string) => store.get(key);

    return {create, get, set};
};