import _ from "lodash";
import {MutationPayload, Store} from "vuex";
import {useLocalstorage} from "@/domain/service/storage/localstorage";


export const snapshotPlugin = (store: Store<any>) => {
    let stateToSave: any | null = null;

    setInterval(async () => {
        if (!stateToSave)
            return;

        const {set} = useLocalstorage();
        const newState = _.cloneDeep(stateToSave);
        await set('snapshot', newState);

        stateToSave = null;
    }, 2000)


    store.subscribe( (mutation: MutationPayload, state: any) => {
        stateToSave = state;
    });
}