import {
    CreateEntryDelivery, CreateExitDelivery, UpdateEntryDelivery, UpdateExitDelivery,
} from "@/domain/command/delivery/delivery.command";
import {EntryDelivery, ExitDelivery} from "@/domain/model/delivery/delivery.model";
import store from '@/domain/store';
import moment from 'moment';
import {http} from "@/domain/service/api/http-api";
import _ from "lodash";
import {sanetizeProducedOrders, useOrderApi} from "@/domain/service/api/order.api";
import {Material} from "@/domain/model/material/material.model";


const sanelizeEntryDelivery = (allMaterials: Material[], entryDelivery: any): EntryDelivery => {
    const newEntryDelivery: EntryDelivery = _.cloneDeep(entryDelivery);
    const newHour = new Date(entryDelivery.createdAt.date).getTime();
    newEntryDelivery.date = Number((moment(newHour).format('X')));
    newEntryDelivery.indexPosition = entryDelivery.deliveryIndex;

    newEntryDelivery.items.forEach((item: any) => {
        const ref: any = item.reference;
        // item.reference = store.getters.materialByRef(ref);
        item.reference = allMaterials.find((material: Material) => material.ref === ref);
    });

    return newEntryDelivery;
};

const sanetizeExitDeliveries = (exitDelivery: any): ExitDelivery => {
    const newExitDelivery = _.cloneDeep(exitDelivery);
    const newHour = new Date(exitDelivery.date).getTime();
    newExitDelivery.date = Number((moment(newHour).format('X')));
    newExitDelivery.indexPosition = exitDelivery.deliveryExit;
    return newExitDelivery;
};


export function useDeliveryApi() {
    const fetchAllExit = async () => {
        const response = await http().get('exit-delivery/');
        const allExitDeliveries = response.data;

        // for (const exitDelivery of allExitDeliveries) {
        //     const sanetizedExitDelivery = sanetizeExitDeliveries(exitDelivery);
        //     store.commit('saveExitDelivery', sanetizedExitDelivery);
        //     for (const exitDeliveryItem of exitDelivery.items){
        //         const sanetizeProducedOrder = sanetizeProducedOrders(exitDeliveryItem.producedOrder)
        //         store.commit('saveProducedOrder', sanetizeProducedOrder);
        //     }
        // }
        const allExitDelivery = [];
        const allProducedOrder = [];
        for (const exitDelivery of allExitDeliveries) {
            allExitDelivery.push(sanetizeExitDeliveries(exitDelivery));
            for (const exitDeliveryItem of exitDelivery.items)
                allProducedOrder.push(sanetizeProducedOrders(exitDeliveryItem.producedOrder));
        }
        store.commit('saveAllExitDelivery', allExitDelivery);
        store.commit('saveAllProducedOrder', allProducedOrder);

    };

    const createExitDelivery = async (command: CreateExitDelivery): Promise<void> => {
        const newCommand: any = _.cloneDeep(command);
        const index = newCommand.indexPosition
        newCommand.indexPosition = Number(index);
        await http().post('exit-delivery/', command);

        await fetchAllExit();
    };

    const fetchById = async (id: string): Promise<void> => {
        const response = await http().get('delivery/entry-delivery/' + id);
        const allMaterials = store.getters.allMaterial;
        const entryDelivery: EntryDelivery = sanelizeEntryDelivery(allMaterials, response.data);
        store.commit('saveEntryDelivery', entryDelivery);
    }

    const fetchAll = async () => {
        const orderApi = useOrderApi();
        await orderApi.fetchAll();
        await fetchAllExit();
        const allMaterials = store.getters.allMaterial;
        const allEntryDeliveries = await http().get('delivery/entry-delivery/');
        const entryDeliveries = allEntryDeliveries.data.map((entry: any) => sanelizeEntryDelivery(allMaterials, entry));
        store.commit('saveAllEntryDelivery', entryDeliveries);
    };

    const create = async (command: CreateEntryDelivery): Promise<void> => {
        const newCommand: any = _.cloneDeep(command);
        newCommand.date = new Date(newCommand.date);
        await http().post('delivery/entry-delivery-create', newCommand);

        await fetchById(command.id);
    };

    const fetchNextEntryIndex = async () => {
        const response = await http().get('delivery/entry-delivery-next-index');
        const index: number = response.data;

        store.commit('saveNextEntryDeliveryIndex', index);
    }

    const fetchNextExitIndex = async () => {
        const response = await http().get('exit-delivery/next-index');
        const index: number = response.data;

        store.commit('saveNextExitDeliveryIndex', index);
    }

    const isIndexUsed = async (index: string) => {
        const response = await http().get('delivery/is-index-free/' + index);
        const isFree: boolean = response.data;
        store.commit('saveIsThisIndexFree', isFree);
    }

    const isExitDeliveryIndexUsed = async (index: string) => {
        const response = await http().get('exit-delivery/is-index-free/' + index);
        const isFree: boolean = response.data;
        store.commit('saveIsThisIndexFree', isFree);
    }

    const updateEntryDelivery = async (command: UpdateEntryDelivery) => {
        const newCommand: any = _.cloneDeep(command);
        newCommand.date = new Date(newCommand.date);
        await http().post('delivery/entry-delivery-update', command);
        await fetchById(command.id);
    }

    const updateExitDelivery = async (command: UpdateExitDelivery) => {
        await http().post('exit-delivery/update', command);
        await fetchAllExit();
    }

    return {
        isExitDeliveryIndexUsed,
        updateExitDelivery,
        updateEntryDelivery,
        createEntryDelivery: create,
        createExitDelivery,
        fetchAll,
        fetchAllExit,
        fetchNextEntryIndex,
        fetchNextExitIndex,
        isIndexUsed
    }
}

