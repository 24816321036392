
import {defineComponent, computed, ref} from "vue";
import {
  mailOutline,
  paperPlaneOutline,
  appsOutline,
  cubeOutline,
  buildOutline,
  personCircleOutline,
  readerOutline,
  airplaneOutline,
  fileTrayFull,
  briefcaseOutline, calculatorOutline,

} from "ionicons/icons";
import {useRoute, useRouter} from "vue-router";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem, IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle, IonNote, isPlatform, menuController
} from "@ionic/vue";
import {useStore} from "vuex";
import {useTriggerDemo} from "@/initialize-demo";
import {initialState, RootState} from "@/domain/store";
import {isManager} from "@/domain/model/user/user.model";
import {useUserApi} from "@/domain/service/api/use-user-api";
import {useConfig} from "@/domain/service/kernel/config";
import BackMenuIcon from "@/app/component/icon/back-menu-icon.vue";
import {useViewStateApi} from "@/domain/service/api/viewState.api";

export default defineComponent({
  name: "Menu",
  components: {
    BackMenuIcon,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonButton,
    IonItemDivider

  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const rootState: RootState = initialState;
    const version = rootState.version;
    const {createDemo} = useTriggerDemo();
    const actualRoute = computed(() => router.currentRoute.value.path);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    // (async () => {
    //       await menuController.enable(true, 'mainMenu');
    //       console.log(await menuController.isEnabled('mainMenu'));
    //       console.log(await menuController.getMenus());
    //       (await menuController.getMenus())[0].close();
    //     }
    // )();
    // setTimeout(async () => {
    //   console.log('open');
    //   await menuController.open('mainMenu');
    // }, 1000);
    // setTimeout(async () => {
    //   console.log('close');
    //
    //
    //   await menuController.close('mainMenu');
    //   console.log((await menuController.getMenus())[0].close);
    //   (await menuController.getMenus())[0].close();
    //
    // }, 4000);

    const showMenu = computed<boolean>(() => store.getters.getShowMenu);
    const onCloseMenu = async () => {
      await useViewStateApi().showMenuToFalse();
      // await menuController.close('mainMenu');
      // await (await menuController.getMenus())[0].close();
    }

    const onLoadDemo = async () => {
      await createDemo();
    }

    const logout = async () => {
      await useUserApi().logout();
      await router.push({name: 'Login'});
    }

    return {
      isTablet,
      showMenu,
      onCloseMenu,
      actualRoute,
      version,
      paperPlaneOutline,
      mailOutline,
      appsOutline,
      cubeOutline,
      buildOutline,
      personCircleOutline,
      readerOutline,
      airplaneOutline,
      fileTrayFull,
      briefcaseOutline,
      calculatorOutline,
      onLoadDemo,
      logout,
      user: computed(() => store.getters.sessionUser),
      isLogged: computed(() => store.getters.isLogged),
      isManager: computed(() => isManager(store.getters.sessionUser)),
      isSelected: (url: string) => url === route.path ? 'selected' : '',
      isProd: useConfig().isProduction()
    }

  }
});
