
import {computed, defineComponent} from "vue";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel, IonMenuButton,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle, IonToolbar
} from "@ionic/vue";
import {
  triangleOutline,
  newspaperOutline,
  addCircleOutline,
} from "ionicons/icons";
import {useDevice} from "@/app/service/device/device";
export default defineComponent({
  name: "EntryDelivery",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonLabel, IonMenuButton,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle, IonToolbar

  },
  setup() {
    const {isMobile} = useDevice();
    const tabsPosition = computed(() => isMobile.value?'bottom':'top');

    return {
      triangleOutline,
      newspaperOutline,
      addCircleOutline,
      tabsPosition
    };
  }
})
