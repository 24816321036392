import moment from "moment";
import _ from "lodash";

export function formatDate(value: any, format: string): string {
    let date = moment();

    if (_.isNumber(value))
         date = moment.unix(value);
    if (_.isString(value))
        date = moment(value);

    return date.format(format);
}