import {Material} from "@/domain/model/material/material.model";
import {uuid4} from "@capacitor/core/dist/esm/util";
import moment from "moment";
import {Order, STATE} from "@/domain/model/order/order.model";

export interface CreateOrderPlan {
    id: string;
    orders: string[];
}

export interface CreatePendingOrder {
    id: string;
    material: Material;
    expectedAmount: number;
    receivedDate: number | string;
    endDate: number;
}

export interface CreateActiveOrder {
    id: string;
    materialId: string;
    expectedAmount: number;
    receivedDate: Date;
    endDate: Date;
}

export interface CreateProducedOrder {
    id: string;
    ref: string;
    orderId: string;
    amount: number;
    date: number;
    comments: string;
    scrap: number;
}

export interface UpdateOrder {
    id: string;
    state: STATE;
}

export function updateFromModel(order: Order) {
    return {
        id: order.id,
        state: order.state,
    };
}

export function emptyProducedOrderCommand() {
    const emptyProducedOrderCommand: CreateProducedOrder = {
        id: uuid4(),
        ref: '',
        orderId: '',
        amount: 0,
        date: moment().unix(),
        comments: '',
        scrap: 0,
    }
    return emptyProducedOrderCommand;
}

export function emptyCreateOrderCommand() {
    const emptyCreateOrderCommand: CreatePendingOrder = {
        id: uuid4(),
        expectedAmount: 0,
        material: {
            id: '',
            ref: '',
            name: '',
            type: 'production',
            components: [],
            scrapRatio: 0.01,
            group: '',
        },
        receivedDate: new Date().getTime(),
        endDate: moment().add(7, 'days').unix(),
    }
    return emptyCreateOrderCommand;
}