import {uuid4} from "@capacitor/core/dist/esm/util";
import {CreateProducedOrder, emptyProducedOrderCommand} from "@/domain/command/order/order.command";
import {Order, ProducedOrder} from "@/domain/model/order/order.model";
import {EntryDelivery, EntryDeliveryItem, ExitDelivery, ExitDeliveryItem} from "@/domain/model/delivery/delivery.model";
import {formatDate} from "@/domain/helper/date.helper";

export interface CreateEntryDelivery {
    id: string;
    indexPosition: string;
    date: number;
    items: CreateEntryDeliveryItem[];
}

export interface CreateEntryDeliveryItem {
    receivedAmount: number;
    expectedAmount: number;
    reference: string;
    id: string;
}

export interface CreateExitDelivery {
    id: string;
    indexPosition: string;
    date: number;
    items: CreateExitDeliveryItem[];
}

export interface CreateExitDeliveryItem {
    id: string;
    amount: number;
    ref: string;
    createProducedOrder: CreateProducedOrder;
}

export interface UpdateEntryDelivery{
    id: string;
    indexPosition: string;
    date: number;
    items: CreateEntryDeliveryItem[];
}

export interface UpdateExitDelivery {
    id: string;
    indexPosition: string;
    date: number;
    items: CreateExitDeliveryItem[];
}


export function emptyExitDeliveryItemCommand() {
    const producedOrder = emptyProducedOrderCommand();
    const emptyCreateExitItemDelivery: CreateExitDeliveryItem = {
        amount: 0,
        ref: producedOrder.ref,
        createProducedOrder: producedOrder,
        id: uuid4(),
    }
    return emptyCreateExitItemDelivery;
}

export function exitDeliveryItemCommandFromOrder(order: Order) {
    const command = emptyExitDeliveryItemCommand();

    command.ref = order.material.ref;
    command.createProducedOrder.orderId = order.id;
    command.createProducedOrder.ref = order.material.ref;

    return command;
}

export function emptyExitDeliveryCommand() {
    const emptyCreateExitDelivery: CreateExitDelivery = {
        id: uuid4(),
        indexPosition: '0',
        date: new Date().getTime(),
        items: [emptyExitDeliveryItemCommand()],
    }
    return emptyCreateExitDelivery;
}

export function createEntryDeliveryItemFromEntity(entryDeliveryItem: EntryDeliveryItem): CreateEntryDeliveryItem
{
    return {
        receivedAmount: entryDeliveryItem.receivedAmount,
        expectedAmount: entryDeliveryItem.expectedAmount,
        reference: entryDeliveryItem.reference.ref,
        id: entryDeliveryItem.id,
    }
}

export function createEntryDeliveryItemsFromEntity(entryDeliveryItems: EntryDeliveryItem[]): CreateEntryDeliveryItem[]
{
    return entryDeliveryItems.map((entryDeliveryItem: EntryDeliveryItem) => createEntryDeliveryItemFromEntity(entryDeliveryItem));
}

export function updateEntryDeliveryFromEntity(entryDelivery: EntryDelivery): UpdateEntryDelivery{
    return {
        id: entryDelivery.id,
        indexPosition: entryDelivery.indexPosition,
        date: Number(formatDate(new Date(), 'X')),
        items: createEntryDeliveryItemsFromEntity(entryDelivery.items),
    }
}

export function createProducedOrderFromEntity(producedOrder: any): CreateProducedOrder
{
    return {
        id: producedOrder.id,
        ref: producedOrder.ref,
        orderId: producedOrder.orderId,
        amount: producedOrder.amount,
        date: producedOrder.date,
        comments: producedOrder.comments,
        scrap: producedOrder.scrapAmount,
}
}

export function createExitDeliveryItemFromEntity(exitDeliveryItem: any): CreateExitDeliveryItem
{
    return {
        id: exitDeliveryItem.id,
        amount: exitDeliveryItem.amount,
        ref: exitDeliveryItem.ref,
        createProducedOrder: createProducedOrderFromEntity(exitDeliveryItem.producedOrder),
    }
}

export function createExitDeliveryItemsFromEntity(exitDeliveryItems: any): CreateExitDeliveryItem[]
{
    return exitDeliveryItems.map((exitDeliveryItem: any) => createExitDeliveryItemFromEntity(exitDeliveryItem));
}

export function updateExitDeliveryFromEntity(exitDelivery: any): UpdateExitDelivery{
    return {
        id: exitDelivery.id,
        indexPosition: exitDelivery.indexPosition,
        date: exitDelivery.date,
        items: createExitDeliveryItemsFromEntity(exitDelivery.items)
    }
}


