const environment = () => {
    return process.env.NODE_ENV;
};

const isProduction = () => {
    return environment() === 'production';
};

export function useConfig() {
    return {
        isProduction,
        environment
    }
}