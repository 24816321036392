
import {computed, defineComponent} from "vue";
import {
  IonButtons,
  IonContent,
  IonHeader, IonIcon, IonLabel,
  IonMenuButton,
  IonPage,
  IonTabBar, IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import {useDevice} from "@/app/service/device/device";
import {addCircleOutline, listOutline, newspaperOutline, triangleOutline} from "ionicons/icons";

export default defineComponent({
  name: "ProductionPlan",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonTitle,
    IonContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonButtons,

  },
  setup() {
    const {isMobile} = useDevice();
    const tabsPosition = computed(() => isMobile.value ? 'bottom' : 'top');
    return {
      listOutline,
      tabsPosition,
      newspaperOutline,
      addCircleOutline,
    };
  }
})
