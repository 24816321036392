import _, {isEqual} from "lodash";
import {Stock, StockRecord, StockTimeline} from "@/domain/model/stock/stock.model";
import {useStock} from "@/domain/service/model/stock.service";
import {Order} from "@/domain/model/order/order.model";
import store from '@/domain/store';

export interface StockState {
    stocks: Stock[];
    StocksTimelines: Record<string, StockTimeline>;
}

const initialState: StockState = {
    stocks: [],
    StocksTimelines: {},
}

export const stockModule = {
    state: () => (_.cloneDeep(initialState)),
    mutations: {
        resetStock: (state: any) => {
            const resetState: any = {...initialState};
            for (const key in resetState) {
                state[key] = _.cloneDeep(resetState[key]);

            }
        },

        saveStock: (state: StockState, newStock: Stock) => {
            if (!_.find(state.stocks, (stock: Stock) => stock.ref === newStock.ref))
                state.stocks.push(newStock);
            else
                state.stocks = state.stocks.map((stock: Stock) => {
                    if (stock.ref === newStock.ref)
                        return newStock;
                    else
                        return stock;
                });

        },

        saveAllStock: (state: StockState, newStocks: Stock[]) => {
            if (isEqual(state.stocks, newStocks))
                return;
            state.stocks = newStocks;
        },

        saveStockTimeline: (state: StockState, newStockTimeline: StockTimeline) => {
            state.StocksTimelines[newStockTimeline.ref] = newStockTimeline;
        },

        updateStockTimeline: (state: StockState, newStockTimeline: StockTimeline) => {
            state.StocksTimelines[newStockTimeline.ref].timeline = newStockTimeline.timeline;
        }

    },
    actions: {},
    getters: {
        lastStockByRef: (state: StockState) => (ref: string): Stock | undefined => {
            return _.findLast(state.stocks, (stock: Stock) => {
                return stock.ref === ref
            })
        },
        lastStockTimelineByRef: (state: StockState) => (ref: string): StockTimeline | undefined => {
            return state.StocksTimelines[ref];
        },
        stockTimelineByRef: (state: StockState) => (ref: string): StockTimeline => {
            return state.StocksTimelines[ref];
        },
        // blockStockByRef: () => (ref: string): number => {
        //     const {calculateBlockStock} = useStock();
        //     const order: Order[] = store.getters.allOrders;
        //
        //     return calculateBlockStock(order, ref);
        // },

        // availableStock: () => (ref: string): number => {
        //     const {calculateAvailableStock} = useStock();
        //     const order: Order[] = store.getters.allOrders;
        //
        //     return calculateAvailableStock(order, ref);
        // }
    },
    plugins: []
};
