import {EntryDelivery, ExitDelivery} from "@/domain/model/delivery/delivery.model";
import _, {cloneDeep, isEqual} from "lodash";
import {pushOrReplace} from "@/domain/helper/array.helper";

export interface DeliveryState {
    entryDeliveries: EntryDelivery[];
    exitDeliveries: ExitDelivery[];
    referenceAndAmount: Record<string, number>;
    nextEntryDeliveryIndex: string;
    nextExitDeliveryIndex: string;
    isThisIndexFree: boolean;
}

const initialState: DeliveryState = {
    entryDeliveries: [],
    exitDeliveries: [],
    referenceAndAmount: {},
    nextEntryDeliveryIndex: '0',
    nextExitDeliveryIndex: '0',
    isThisIndexFree: true,
}

export const deliveryModule = {
    state: () => (_.cloneDeep(initialState)),
    mutations: {
        resetDeliveries: (state: any) => {
            const resetState: any = {...initialState};
            for (const key in resetState)
                state[key] = _.cloneDeep(resetState[key]);
        },

        saveEntryDelivery: (state: DeliveryState, newEntryDelivery: EntryDelivery) => {
            // const oldEntryDeliveries: EntryDelivery[] = _.cloneDeep(state.entryDeliveries);
            // oldEntryDeliveries.push(newEntryDelivery);
            // state.entryDeliveries = oldEntryDeliveries;
            // state.entryDeliveries.push(newEntryDelivery);
            state.entryDeliveries = pushOrReplace(state.entryDeliveries, newEntryDelivery);

        },
        saveAllEntryDelivery: (state: DeliveryState, newEntryDeliveries: EntryDelivery[]) => {
            if (isEqual(state.entryDeliveries, newEntryDeliveries))
                return;
            state.entryDeliveries = newEntryDeliveries;
        },
        saveExitDelivery: (state: DeliveryState, newExitDelivery: ExitDelivery) => {
            state.exitDeliveries = pushOrReplace(state.exitDeliveries, newExitDelivery);
        },
        saveAllExitDelivery: (state: DeliveryState, newExitDeliveries: ExitDelivery[]) => {
            if (isEqual(state.exitDeliveries, newExitDeliveries))
                return;
            state.exitDeliveries = newExitDeliveries;
        },
        saveReferenceAmount: (state: DeliveryState, referenceAmount: { ref: string; amount: number }) => {
            state.referenceAndAmount[referenceAmount.ref] = referenceAmount.amount;
        },
        saveNextEntryDeliveryIndex: (state: DeliveryState, nextIndex: string) => {
            state.nextEntryDeliveryIndex = nextIndex;
        },
        saveNextExitDeliveryIndex: (state: DeliveryState, nextIndex: string) => {
            state.nextExitDeliveryIndex = nextIndex;
        },
        saveIsThisIndexFree: (state: DeliveryState, isFree: boolean) => {
            state.isThisIndexFree = isFree;
        },
    },
    actions: {},
    getters: {
        allEntryDeliveries: (state: DeliveryState): EntryDelivery[] => [...state.entryDeliveries],
        allExitDeliveries: (state: DeliveryState): ExitDelivery[] => [...state.exitDeliveries],
        entryDeliveryById: (state: DeliveryState) => (id: string) => _.find(state.entryDeliveries, ['id', id]),
        exitDeliveryById: (state: DeliveryState) => (id: string) => _.find(state.exitDeliveries, ['id', id]),
        lastReferenceAmountReceived: (state: DeliveryState) => (ref: string) => state.referenceAndAmount[ref] || 0,
        getNextEntryDeliveryIndex: (state: DeliveryState): string => state.nextEntryDeliveryIndex,
        getNextExitDeliveryIndex: (state: DeliveryState): string => state.nextExitDeliveryIndex,
        getIsThisIndexFree: (state: DeliveryState): boolean => state.isThisIndexFree,
    },
    plugins: []
};
