import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import Material from "@/app/view/material/Material.vue";
import EntryDelivery from "@/app/view/delivery/EntryDelivery.vue";
import Production from "@/app/view/production/Production.vue";
import ProductionPlan from "@/app/view/order/Order.vue";
import Order from "@/app/view/order/Order.vue";
import Employee from "@/app/view/user/User.vue";
import Stock from "@/app/view/stock/Stock.vue";
import ExitDelivery from "@/app/view/delivery/ExitDelivery.vue";
import ProductionBonus from "@/app/view/bonus/ProductionBonus.vue";
import store from '@/domain/store';

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: '/login',
        name: 'Home',
    },
    {
        path: '/loading/:fullPath',
        name: 'Loading',
        component: () => import ('../view/loading/Loading.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import ('../view/auth/Login.vue')
    },
    {
        path: '/app/dashboard',
        name: 'Dashboard',
        component: () => import ('../view/dashboard/Dashboard.vue')
    },
    {
        path: '/app/empleados/',
        component: Employee,
        children: [
            {
                path: '',
                redirect: '/app/empleados/listado-empleados'
            },
            {
                path: '/app/empleados/listado-empleados',
                name: 'UsersList',
                component: () => import ('../view/user/list/UserListPage.vue')
            },
            {
                path: '/app/empleados/listado-usuarios',
                name: 'UsersWithAppAccessList',
                component: () => import ('../view/user/appAccessList/UsersWithAppAccessListPage.vue')
            },
            {
                path: '/app/empleados/bonus-diario',
                name: 'UsersDailyBonus',
                component: () => import ('../view/bonus/list/ProductionBonusDailyListPage.vue')
            },
            {
                path: '/app/empleados/bonus-mensual',
                name: 'UsersMonthlyBonus',
                component: () => import ('../view/bonus/list/ProductionBonusMonthlyListPage.vue')
            },
        ],
    },
    {
        path:'/app/empleados/bonus-detalles/:id',
        name: 'UserBonusDetailsUser',
        component: () => import('../view/user/userBonusDetail/UserBonusDetailPage.vue')
    },
    {
        path:'/app/empleados/perfil-update/:id',
        name: 'UserProfileUpdate',
        component: () => import('../view/user/profile/UserBonusDetailsUserPage.vue')
    },
    {
        path: '/app/materiales/',
        component: Material,
        children: [
            {
                path: '',
                redirect: '/app/materiales/listado'
            },
            {
                path: 'listado',
                name: 'MaterialsList',
                component: () => import ('../view/material/list/MaterialListPage.vue')
            },
        ]

    },
    {
        path: '/app/materiales/listado/detalle/:id',
        name: 'MaterialDetail',
        component: () => import ('../view/material/detail/MaterialDetailPage.vue')
    },
    {
        path: '/app/albaran',
        component: EntryDelivery,
        children: [
            {
                path: '',
                redirect: '/app/albaran/historial'
            },
            {
                path: 'historial',
                name: 'EntryDeliveryRecord',
                component: () => import ('../view/delivery/list/EntryDeliveryListPage.vue')
            },
            {
                path: 'crear-albaran',
                name: 'addEntryDelivery',
                component: () => import ('../view/delivery/create/CreateEntryDeliveryPage.vue')
            },
        ],

    },
    {
        path: '/app/revisar-albaran/:id',
        name: 'checkEntryDelivery',
        component: () => import ('../view/delivery/check/CheckCreateEntryDeliveryPage.vue')
    },
    {
        path:'/app/revisar-actualizacion-albaran/:id',
        name:'checkUpdateEntryDelivery',
        component: () => import('../view/delivery/check/CheckUpdateEntryDeliveryPage.vue')
    },
    {
        path: '/app/albaran-salida/:id',
        name: 'detailsExitDelivery',
        component: () => import ('../view/delivery/details/DetailsExitDeliveryPage.vue')
    },
    {
        path: '/app/detalles-albaran/:id',
        name: 'detailsEntryDelivery',
        component: () => import ('../view/delivery/details/DetailsEntryDeliveryPage.vue')
    },
    {
        path: '/app/produccion/',
        component: Production,
        children: [
            {
                path: '',
                redirect: '/app/produccion/historial-stock'
            },
            {
                path: 'historial-stock',
                name: 'StockRecord',
                component: () => import ('../view/production/list/ProductionListPage.vue')
            },
            {
                path: 'create-product',
                name: 'ProductCreatePage',
                component: () => import ('../view/production/create/ProductCreatePage.vue')
            },
        ],
    },
    {
        path: '/app/detalles-produccion/:id',
        name: 'detailsProduct',
        component: () => import('../view/production/details/DetailsProductionItemPage.vue')
    },

    {
        path: '/app/planificaciones-produccion',
        component: Order,
        children: [
            {
                path: '',
                redirect: '/app/planificaciones-produccion/actuales'
            },
            {
                path: 'actuales',
                name: 'OrderListPage',
                component: () => import ('../view/order/list/planList/OrderListPage.vue')
            },
            {
                path: 'historial',
                name: 'OrderRecord',
                component: () => import ('../view/order/list/recordList/OrderRecordListPage.vue')
            },
            {
                path: 'crear-planificacion',
                name: 'AddOrderPlan',
                component: () => import ('../view/order/list/createOrderList/AddOrderPlanListPage.vue')
            },
        ],

    },

    {
        path: '/app/detalles-planificacion/:id',
        name: 'detailsOrder',
        component: () => import('../view/order/details/DetailsOrderPage.vue')
    },


    {
        path: '/app/stock',
        component: Stock,
        children: [
            {
                path: '',
                redirect: '/app/stock/lista-stock'
            },
            {
                path: 'lista-stock',
                name: 'StockList',
                component: () => import ('../view/stock/list/StockListPage.vue')
            },
        ],
    },
    {
        path: '/app/historial-stock/:id',
        name: 'StockItemRecordPage',
        component: () => import ('../view/stock/details-record/StockItemRecordPage.vue')
    },
    {
        path: '/app/albaran-salida',
        component: ExitDelivery,
        children: [
            {
                path: '',
                redirect: '/app/albaran-salida/historial'
            },
            {
                path: 'historial',
                name: 'ExitDeliveryRecord',
                component: () => import ('../view/delivery/list/ExitDeliveryListPage.vue')
            },
            {
                path: 'crear-albaran-salida',
                name: 'CreateExitDeliveryPage',
                component: () => import ('../view/delivery/create/CreateExitDeliveryPage.vue')
            },
        ],
    },
    {
        path: '/app/revisar-albaran-salida/:id',
        name: 'checkExitDelivery',
        component: () => import ('../view/delivery/check/CheckCreateExitDeliveryPage.vue')
    },
    {
        path: '/app/revisar-actualizacion-albaran-salida/:id',
        name: 'checkUpdateExitDelivery',
        component: () => import ('../view/delivery/check/CheckUpdateExitDeliveryPage.vue')
    },
    {
        path: '/app/incentivos-bonus',
        component: ProductionBonus,
        children: [
            {
                path: '',
                redirect: '/app/incentivos-bonus/tabla-rangos-material'
            },
            {
                path: 'tabla-rangos-material',
                name: 'ProductionBonusMaterialRangesTablePage',
                component: () => import ('../view/bonus/table/ProductionBonusMaterialRangesTablePage.vue')
            },
        ],
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const fullPath = to.fullPath;

    if (to.name === 'Loading') {
        next();
        return;
    }

    if (!store.getters.isRestored) {
        console.log('beforeEach', fullPath);
        next({name: 'Loading', params: {fullPath}, replace: true});
        return;
    }

    const isAuthenticated = store.getters.isLogged;
    // console.log('Router', to.name, isAuthenticated);

    if (to.name !== 'Login' && !isAuthenticated) next({name: 'Login'});
    else if (to.name === 'Login' && isAuthenticated) next({name: 'MaterialsList'});
    else next();
});

export default router
