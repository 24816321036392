import {MutationPayload, Store} from "vuex";
import {EntryDelivery, EntryDeliveryItem} from "@/domain/model/delivery/delivery.model";


export const amountPredictPlugin = (store: Store<any>) => {

    store.subscribe((mutation: MutationPayload) => {
        if (mutation.type !== 'saveEntryDelivery')
            return;

        const entryDelivery: EntryDelivery = mutation.payload;

        entryDelivery.items.forEach((item: EntryDeliveryItem) => {
            store.commit('saveReferenceAmount', {ref: item.reference.ref, amount: item.expectedAmount});
        });

    });
}