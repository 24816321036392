
import {computed, defineComponent} from "vue";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel, IonMenuButton,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle, IonToolbar
} from "@ionic/vue";
import {
  triangleOutline,
  newspaperOutline,
  addCircleOutline, listOutline,
} from "ionicons/icons";
import {useDevice} from "@/app/service/device/device";
export default defineComponent({
  name: "Production",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonTitle,
    IonContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonButtons,

  },
  setup() {
    const {isMobile} = useDevice();
    const tabsPosition = computed(() => isMobile.value?'bottom':'top');
    return {
      newspaperOutline,
      tabsPosition,
      listOutline,
      addCircleOutline,
    };
  }
})
