import axios from "axios";
import {useConfig} from "@/domain/service/kernel/config";
import store from '@/domain/store';

export interface Response<T = any> {
    data: T;
    status: number;
    statusText: string;
    headers: any;
    request?: any;
}

export function authHeader() {

    try {
        const sessionUser = JSON.parse(localStorage.getItem('sessionUser') as string);
        if (sessionUser && sessionUser.token) {
            return {Authorization: 'Bearer ' + sessionUser.token};
        } else {
            return {};
        }
    } catch (e) {
        return {};
    }
}

export function http() {

    if (!useConfig().isProduction())
        axios.defaults.baseURL = 'http://192.168.1.42:8062/api/v1/';
        // axios.defaults.baseURL = 'http://localhost:8062/api/v1/';
    else
        axios.defaults.baseURL = 'https://catedier-api.dinacode.com/api/v1/';

    async function get<T = any, R = Response<T>>(url: string, disableAuthHeaders?: boolean): Promise<R> {
        const absoluteUrl = axios.defaults.baseURL + url;
        let config: any = {headers: authHeader()};

        if (disableAuthHeaders) {
            config = {};
        }
        
        return axios
            .get(absoluteUrl, config);
    }

    async function post<T = any, R = Response<T>>(url: string, data?: any, disableAuthHeaders?: boolean): Promise<R> {
        let config: any = {headers: authHeader()};

        if (disableAuthHeaders)
            config = {};

        return axios.post(axios.defaults.baseURL + url, data, config);
    }

    return {get, post};
}


