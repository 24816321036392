
import {defineComponent} from 'vue';
import {NIcon} from 'naive-ui';

export default defineComponent({
  name: 'back-menu-icon',
  components: {
    NIcon,
  },
  props: {
    color: String,
    size: Number,
  },
  setup() {
    return {};
  },
});
