import {CreateUser, LoginUser, UpdateUser, UpdateUserWithProfileData} from "@/domain/command/user/user.command";
import {User} from "@/domain/model/user/user.model";
import store from '@/domain/store';
import {authHeader, http} from "@/domain/service/api/http-api";
import _ from "lodash";

export const defaultUser: User = {
    id: 'aId',
    username: 'Ernesto',
    email: 'ernesto@gmail.com',
    charge: 'manager_production',
    shift: 'central',
    token: '',
    name: '',
    surname: '',
    address: '',
    phone: '',
    dni: '',
    appAccess: '',
    workerNumber: 0,
    dailyBonusIncome: 0,
};

function assignProfileToUser(user: any): User{
    const newUser: User = _.cloneDeep(user);
    newUser.name = user[0]['userProfile.name'];
    newUser.surname = user[0]['userProfile.surname'];
    newUser.address = user[0]['userProfile.address'];
    newUser.phone = user[0]['userProfile.phone'];
    newUser.dni = user[0]['userProfile.dni'];
    newUser.shift = user[0]['shift'];
    newUser.workerNumber = user[0]['workerNumber'];
    newUser.appAccess = user[0]['appAccess'];
    newUser.dailyBonusIncome = user[0]['dailyBonusIncome'];
    return newUser;
}


const login = async (command: LoginUser): Promise<User> => {
    const response: any = await http().post('login_check', command, true);

    const sessionUser: User = response.data.rawUser;
    sessionUser.token = response.data.token
    store.commit('saveSessionUser', sessionUser);
    localStorage.setItem('sessionUser', JSON.stringify(sessionUser));
    return sessionUser;
};

const logout = async () => {
    store.commit('logoutUser');
    localStorage.setItem('sessionUser', '');
};

const fetchAll = async (): Promise<void> => {
    const response = await http().get('user/');
    const users: User[] = response.data;

    users.forEach((user) => {
        store.commit('updateUser', assignProfileToUser(user));
    });
};

const fetchUser = async (userId: string): Promise<void> => {
    const response = await http().get('user/' + userId);

    const user: User = response.data;

    store.commit('updateUser', assignProfileToUser(user));
};

const updateUser = async (command: UpdateUser): Promise<void> => {
    await http().post('user/update-user', command);

    await fetchUser(command.id);
};

const createUser = async (command: CreateUser): Promise<void> => {
    await http().post('user/create-user', command);

    await fetchUser(command.id);
};

const removeUser = async (user: User): Promise<void> => {
    store.commit('removeUser', user);
};

const updateUserProfile = async (command: UpdateUserWithProfileData): Promise<void> => {
    await http().post('user/update-user-profile', command);
    await fetchUser(command.id);
}

const createAndUpdateProfileAllUsers = async (createCommand: CreateUser[], updateCommand: UpdateUserWithProfileData[], updateUsersCommand: UpdateUser[]): Promise<void> => {
    const createRequests = [];
    const updateRequests = [];
    const updateUsersRequests = [];
    for(const createCommandNode of createCommand)
        createRequests.push(http().post('user/create-user', createCommandNode))
    const allCreateRequests = Promise.all(createRequests);
    await allCreateRequests;
    for(const updateCommandNode of updateCommand)
        updateRequests.push(http().post('user/update-user-profile', updateCommandNode))
    const allUpdateRequests = Promise.all(updateRequests);
    await allUpdateRequests;
    for(const updateUsersCommandNode of updateUsersCommand)
        updateUsersRequests.push(http().post('user/update-user', updateUsersCommandNode))
    const allUpdateUsersRequests = Promise.all(updateUsersRequests);
    await allUpdateUsersRequests;
    await fetchAll();
}

export function useUserApi() {

    return {
        login,
        createUser,
        removeUser,
        updateUser,
        fetchAll,
        logout,
        updateUserProfile,
        createAndUpdateProfileAllUsers,
    };
}
