import _ from "lodash";
import {BonusMaterial} from "@/domain/model/bonusMaterial/bonusMaterial.model";

export interface BonusMaterialState {
    bonusMaterials: BonusMaterial[];
}

const initialState: BonusMaterialState = {
    bonusMaterials: [],
}

export const bonusMaterialModule = {
    state: () => (_.cloneDeep(initialState)),
    actions: {},

    mutations: {
        saveBonusMaterial: (state: BonusMaterialState, newBonusMaterial: BonusMaterial): any =>
        {
            if (_.some(state.bonusMaterials, (bonusMaterialNode: BonusMaterial) => bonusMaterialNode.ref === newBonusMaterial.ref)) {
                state.bonusMaterials = state.bonusMaterials.map((bonusMaterialNode: BonusMaterial) => {
                    if (bonusMaterialNode.id === newBonusMaterial.id || bonusMaterialNode.ref === newBonusMaterial.ref) {
                        return newBonusMaterial;
                    } else {
                        return bonusMaterialNode;
                    }
                });
            } else {
                state.bonusMaterials.push(newBonusMaterial)
            }
        },
    },
    getters: {
        allBonusMaterial: (state: BonusMaterialState) => state.bonusMaterials,
        bonusMaterialById: (state: BonusMaterialState) => (id: string) => _.find(state.bonusMaterials, ['id', id]),
        bonusMaterialByMaterialRef: (state: BonusMaterialState) => (ref: string) => _.find(state.bonusMaterials, ['ref', ref])
    },
    plugins: []
};