import {User} from "@/domain/model/user/user.model";
import _ from "lodash";
import {pushOrReplace} from "@/domain/helper/array.helper";

export interface UserState {
    user: User | null;
    users: User[];
}

const initialState: UserState = {
    user: null,
    users: [],
};

export const userModule = {
    state: () => (_.cloneDeep(initialState)),
    mutations: {
        resetUsers: (state: any) => {
            const resetState: any = {...initialState};
            for (const key in resetState)
                state[key] = _.cloneDeep(resetState[key]);
        },
        saveSessionUser: (state: UserState, user: User) => {
            return state.user = user;
        },
        logoutUser: (state: UserState) => {
            return state.user = null;
        },
        createUser: (state: UserState, newUser: User) => {
            if (_.some(state.users, (userNode: User) => userNode.id === newUser.id || userNode.email === newUser.email))
                return;

            state.users.push(newUser);
        },

        removeUser: (state: UserState, user: User) => {
            _.remove(state.users, user);
        },

        updateUser: (state: UserState, updatedUser: User) => {
            state.users = pushOrReplace(state.users, updatedUser);
        }
    },

    actions: {},
    getters: {
        sessionUser: (state: UserState): User | null => state.user,
        isLogged: (state: UserState): boolean => state.user !== null && !_.isEmpty(state.user),
        userById: (state: UserState) => (id: string) => _.find(state.users, ['id', id]),
        allUsers: (state: UserState): User[] => [...state.users],
        allOperatorsUsers: (state: UserState): User[] => _.filter(state.users, (user: User) => user.charge === 'employee_production'),
        userByUsername: (state: UserState) => (username: string) => _.find(state.users, ['username', username]),
        userByEmail: (state: UserState) => (email: string) => _.find(state.users, ['email', email]),
        userByWorkNumber: (state: UserState) => (workerNumber: number) => state.users.find((userNode: User) => userNode.workerNumber === workerNumber),
    }
};
