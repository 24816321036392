import {ColumnOrder} from "@/domain/model/view/columnOrder.model";
import _ from "lodash";

export interface ViewColumnOrder {
    columnOrder: ColumnOrder;
}

export const initialState: ViewColumnOrder = {
    columnOrder: {
        order: '',
        key: '',
    }
}

export const emptyState = _.cloneDeep(initialState);
export const viewColumnOrderModule = {
    state: () => (_.cloneDeep(initialState)),
    mutations: {
        resetViewColumnOrder: (state: any) => {
            const resetState: any = {...initialState};
            for (const key in resetState) {
                state[key] = _.cloneDeep(resetState[key]);

            }
        },
        saveColumnOrder: (state: ViewColumnOrder, columnOrder: ColumnOrder) => {
            state.columnOrder = columnOrder;
        }
    },
    getters: {
        getColumnOrder: (state: ViewColumnOrder): ColumnOrder => state.columnOrder,
    }
}