export type Charges = 'employee_stock' | 'ceo' | 'employee_production' | 'manager_production';
export type Shift = 'morning' | 'afternoon' | 'night' | 'central' | 'sick_leave'


export interface User {
    id: string;
    username: string;
    email: string | undefined;
    charge: Charges;
    token: string;
    name: string;
    surname: string;
    address: string;
    phone: string;
    dni: string;
    shift: Shift;
    appAccess: string;
    workerNumber: number;
    dailyBonusIncome: number;
}

export function isManager(user: User | null): boolean {
    if (!user || !user.charge)
        return false;

    return user.charge === 'ceo' || user.charge === 'manager_production';
}

export function allShifts(): Shift[] {
    return ['morning', 'afternoon', 'night', 'central', 'sick_leave'];
}

export function getShift(shift: Shift): string {
    if (shift === 'morning')
        return 'Mañanas';
    if (shift === 'afternoon')
        return 'Tardes';
    if (shift === 'night')
        return 'Noches';
    if (shift === 'central')
        return 'Central';
    else
        return 'Baja médica';
}
