
import {computed, defineComponent} from "vue";
import {
  IonButtons,
  IonContent,
  IonHeader, IonIcon, IonLabel,
  IonMenuButton,
  IonPage, IonTabBar,
  IonTabButton, IonTabs,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import {useDevice} from "@/app/service/device/device";
import {calendarOutline, peopleOutline, todayOutline} from "ionicons/icons";

export default defineComponent({
  name: "ProductionBonus",
  components: {
    IonPage,
    IonHeader,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonContent,
    IonTabButton,
    IonLabel,
    IonIcon,
    IonTabs,
    IonTabBar,

  },
  setup() {
    const {isMobile} = useDevice();
    const tabsPosition = computed(() => isMobile.value?'bottom':'top');
    return {
      tabsPosition,
      todayOutline,
      calendarOutline,
    };
  }
})
