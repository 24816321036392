import {createStore, StoreOptions} from "vuex";
import {userModule} from "@/domain/store/user/user-module";
import {snapshotPlugin} from "@/domain/store/plugin/snapshot.plugin";
import {referenceModule} from "@/domain/store/reference/reference-module";
import {deliveryModule} from "@/domain/store/delivery/delivery-module";
import {viewStateModule} from "@/domain/store/viewState/view-state.module";
import _ from "lodash";
import {amountPredictPlugin} from "@/domain/store/plugin/amountPredict.plugin";
import {orderModule} from "@/domain/store/order/order-module";
import {stockModule} from "@/domain/store/stock/stock-module";
import {bonusModule} from "@/domain/store/productionBonus/bonus-module";
import {bonusMaterialModule} from "@/domain/store/bonusMaterial/bonusMaterial-module";
import {viewColumnOrderModule} from "@/domain/store/viewState/view-columnOrder.module";


export interface RootState {
    version: string;
    restored: boolean;
}

export const initialState: RootState =
    {
        version: '0.9.23',
        restored: false
    };


const option: StoreOptions<any> = {
        state: initialState,
        mutations: {
            restore: (state: any, snapshot: any) => {
                // if (snapshot.version !== null)
                //     if (snapshot.version !== initialState.version)
                //         return;

                if (_.isEmpty(snapshot)) {
                    state.restored = true;
                    return;
                }

                if (_.isObject(snapshot.user) && _.isObject(snapshot.user.user))
                    state.user.user = _.merge(state.user.user, snapshot.user?.user);

                state.restored = true;

                // state.delivery = _.merge(state.delivery, snapshot.delivery);
                // state.reference = _.merge(state.reference, snapshot.reference);
                // state.order = _.merge(state.order, snapshot.order);
                // state.stock = _.merge(state.stock, snapshot.stock);

                // state.StockTimeline = _.merge(state.stock, snapshot.stock);

                // if (!snapshot.materials || snapshot.materials.length === 0) {
                //     const {createMaterial} = useMaterialApi();
                //     const commands = materialDemo;
                //     const importedRef: string[] = [];
                //
                //     commands.forEach((command) => {
                //         if (importedRef.includes(command.ref))
                //             return;
                //
                //         importedRef.push(command.ref);
                //         createMaterial(command);
                //     });
                //
                // }

            },

        },
        modules: {
            user: userModule,
            reference: referenceModule,
            delivery: deliveryModule,
            order: orderModule,
            viewState: viewStateModule,
            stock: stockModule,
            bonus: bonusModule,
            bonusMaterial: bonusMaterialModule,
            viewColumnOrder: viewColumnOrderModule,
        },
        getters: {
            isRestored: (state): boolean => state.restored
        },
        plugins: [
            snapshotPlugin,
            amountPredictPlugin,
            // updateEntryStockPlugin,
            // createStockPlugin,
            // updateExitStockPlugin,
            // createStockFromMaterialsArrayPlugin,
        ]
    }
;
export default createStore(option);
