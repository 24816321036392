
import {IonApp, IonRouterOutlet, IonSplitPane} from '@ionic/vue';
import {computed, defineComponent} from 'vue';
import Menu from "@/app/component/menu/Menu.vue";
import {useLocalstorage} from "@/domain/service/storage/localstorage";
import {useStore} from "vuex";
import _ from "lodash";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useOrderApi} from "@/domain/service/api/order.api";
import {useStockApi} from "@/domain/service/api/stock.api";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";


export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default defineComponent({
  name: 'App',
  components: {
    Menu,
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    console.log('======== Catedier Logistics =========');
    const {create, get} = useLocalstorage();
    const {commit} = useStore();
    const store = useStore();
    const isDataRestored = computed(() => store.getters.isRestored);

    _.defer(async () => {
      await create();

      const snapshot = await get('snapshot');
      commit('restore', snapshot);

      await useDeliveryApi().fetchNextEntryIndex();
      await useDeliveryApi().fetchNextExitIndex();
      // await useMaterialApi().fetchAll();
      // await useStockApi().fetchAll();
      // await useOrderApi().fetchAll();
      // await useDeliveryApi().fetchAll();
      // await useDeliveryApi().fetchAllExit();
    });

    return {isDataRestored};
  }


});
