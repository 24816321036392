import _ from "lodash";

export function pushOrReplace<T>(array: T[], entry: T): T[] {
    const genericEntry: any = entry;
    let newArray = _.cloneDeep(array);

    if (_.some(newArray, (node: any) => node.id === genericEntry.id))
        newArray = newArray.map((node: any) => (node.id === genericEntry.id) ? entry : node);
    else
        newArray.push(entry)

    return newArray;
}